<template>
    <div class="action-meeting-feedback">
        <template>
            <div class="message">
                <div class="message-top">
                    <img class="m-r-8" src="@/assets/images/icons/feedback.png" width="24px" height="24px" alt="" />
                    <span class="f-16 spoqa-f-bold">이번 만남 어떠셨나요?</span>
                </div>
                <div class="grey-box">
                    <div class="user-profile">
                        <!-- <img src="https://media.istockphoto.com/photos/productivity-powered-by-digital-technology-picture-id1330965067" alt="" /> -->
                        <img :src="getProfileImage" alt="" class="message-profile-image" />
                        <div class="profile-info">
                            <div class="person-info">
                                <span>{{ content.name }}</span>
                                <span>, {{ getAge }}세</span>
                            </div>
                            <div class="meeting-date">{{ getDate }} 만남</div>
                        </div>
                    </div>
                </div>
                <div class="feedback-btn">
                    <BottomButton
                        :nonFixed="true"
                        label="만남 피드백하기"
                        :height="40"
                        :fontSize="14"
                        :lineHeight="24"
                        @click="onClickFeedback"
                        :disabled="disabled"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionMeetingFeedback',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        getAge() {
            return this.$options.filters.asAge(this.content.birthday)
        },
        getDate() {
            const dateConfirmed = this.content.date_confirmed
            const date = this.$moment(dateConfirmed).format('YY/MM/DD')
            const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
            return `${date} ${this.$translate(day)}`
        },
        getProfileImage() {
            return this.content.urls[0]
        },
        disabled() {
            return this.content.status ? true : false
        },
    },

    methods: {
        onClickFeedback() {
            // console.log('쏘쓰', this.content.source_id)
            this.$stackRouter.push({
                name: 'MeetingFeedback',
                props: {
                    person: this.content.name,
                    message: this.message,
                    scheduleId: this.content.schedule_id,
                    targetId: this.content.source_id,
                },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.action-meeting-feedback {
    padding: 0 16px;
    .message {
        .message-top {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
        }

        .message-content {
            line-height: 26px;
            color: black;

            p {
                margin-bottom: 16px;
            }
        }

        .grey-box {
            background: $grey-01;
            padding: 8px;
            font-size: 14px;
            border-radius: 12pxcc;

            .user-profile {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 24px;
                color: #111111;

                img {
                    width: 64px;
                    height: 64px;
                    border: 1px solid white;
                    border-radius: 8px;
                    margin-right: 14px;
                    object-fit: contain;
                }
            }
            .title-grey {
                @include spoqa-f-medium;
                font-size: 12px;
                color: #94999e;
                margin-bottom: 4px;
            }
            .content-grey {
                line-height: 1.57;
                @include spoqa-f-regular;
            }
        }

        .feedback-btn {
            margin: 16px 0;
        }
    }
}
</style>
